// @import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");

// $body-bg: #fff;
// $body-color: #123953;

$theme-colors: (
    "site-primary": #c1f7dc,

    "site-secondary": #c3e1ef,
    "site-dark-secondary": #3ea3b8,
    "site-lite-secondary": #e5f2f8,

    "site-dark": #343434,
    "site-gray": #545454,

    "site-heading-dark": #2d2d2a,
    "site-heading-color": #2d2d2d,

    "card-text-heading": #52b1c2 // "txt-footer-color": #a7a6a6,
    // "promotion-text": #ffffff,
    // "site-pink": #ffe4cc,,,,,,,,,,,,,,,,,,,,,
);

@import "node_modules/bootstrap/scss/bootstrap";

body {
    overflow-x: hidden;
    font-family: "Roboto", sans-serif !important;
    // color: #123953;
}
.roboto-font {
    font-family: "Roboto", sans-serif !important;
}
.montserrat-font {
    font-family: "Montserrat", sans-serif !important;
}
.nanum-font {
    font-family: "Nanum Pen Script", cursive !important;
}
.nanum-font {
    font-family: "Nanum Pen Script", cursive !important;
}

html {
    font-size: 18px;
    @media (max-width: 1799.98px) {
        font-size: 17px;
    }
    @media (max-width: 1399.98px) {
        font-size: 16px;
    }
    @media (max-width: 1199.98px) {
        font-size: 13px;
    }
    @media (max-width: 991.98px) {
        font-size: 12px;
    }
    @media (max-width: 567.98px) {
        font-size: 11px;
    }
}
$weights: 100 200 300 400 500 500 600 700 800;
@each $weight in $weights {
    .fw--#{$weight} {
        font-weight: $weight;
    }
}
@for $size from 9 through 96 {
    .fs--#{$size} {
        font-size: $size/16 + rem;
    }
}

.cursor-pointer {
    cursor: pointer;
}
.hero-section {
    height: 43em !important;
    background-image: url("../../assets/img/backgroundImage.jpg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 992.98px) {
        height: 46em !important;
        h1 {
            font-size: 28px !important;
        }
    }
    @media (max-width: 767.98px) {
        height: auto !important;
        h1 {
            font-size: 28px !important;
        }
    }
    @media (max-width: 370.98px) {
        height: auto !important;
        h1 {
            font-size: 22px !important;
        }
    }
}
.disable-copy {
    user-select: none;
}
.btn-style {
    background-color: #3ea3b8;
    border: none;
    box-shadow: 5px 6px #2d7480;
    color: #e5f2f8;
}
.btn-style:hover {
    background-color: #2d7480;
    box-shadow: 5px 6px #3ea3b8;
}
.form-card {
    border-top-right-radius: 3em;
    border-bottom-left-radius: 3rem;
}
.insurance-comparator {
    padding-top: 36em;

    @media (max-width: 767.98px) {
        padding-top: 3em;
        background-color: #ffffff !important;
    }
}
.faq-card {
    border-top-left-radius: 2em;
    border-bottom-right-radius: 2em;
    h5 {
        border-top-left-radius: 1.7em;
    }
}
.anyquestion-section {
    height: 100%;
    width: 90%;
    border-top-right-radius: 6em;
    // padding-left: 3rem;
    @media (max-width: 767.98px) {
        width: 100% !important;
    }
}
.custom-input {
    &:focus {
        background-color: #ffffff !important;
    }
    &:active {
        background-color: #ffffff !important;
    }
    &::after {
        background-color: #ffffff !important;
    }
    &::-webkit-autofill {
        background-color: #ffffff !important;
    }
}
.footer {
    a {
        cursor: pointer;
        text-decoration: underline;
        color: #175da6;
        font-size: 14px !important;
        font-weight: 400;
        font-family: Arial, sans-serif;
        @media (max-width: 767.98px) {
            font-size: 12px !important;
        }
        &:hover {
            /* The correct syntax for pseudo-class hover */
            color: #175da6 !important;
        }
    }
}
.modal-scrollable {
    max-height: calc(100vh - 200px); /* Adjust the value as needed */
    overflow-y: auto;
}

.scrollable-content {
    padding: 15px; /* Adjust padding as needed */
}
